import React, {useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Banner } from '../Banner/Banner';
import { CMSService } from "@vaccinessites/services";
import './PublicationPage.scss';

export function PublicationPage(props){
    const [publication, setPublication]= useState('');
    const {slug} = useParams();
    const divStyle = { backgroundColor: publication.tagcolor};
    let history = useHistory();
    useEffect(()=>{
        props.CMS.getPublication(slug).then(publicationData=>{
            if(publicationData){
                setPublication(publicationData.fields)
                document.title = publicationData.fields.title
            } else {
                history.push('/404')
            }
        })
    }, [slug])
    let noSideBar = !publication.sidebar && !publication.imageBanner
    return (
        <div className="publication-page">
            <div className="row">
                <div className={"main-column col-12 justify-content-between"}>
                        <h2 className={"col-12"}>
                        {publication.imageBanner && publication.imageBanner.fields.file.contentType.split("/")[0] === "image" ?
                        <img className="img-fluid" src={publication.imageBanner.fields.file.url}  alt={publication.imageBanner.fields.description} /> : 
                        publication.imageBanner &&  
                        <video className="img-fluid" controls>
                            <source src={publication.imageBanner.fields.file.url} ></source>
                        </video>}
                        </h2>
                        <h2 className={"col-4"}>{publication.title}</h2>
                        <h2 className={"img-tag col-4"} style={{backgroundColor: publication.tagColor, color: publication.fontColor}}>{publication.categorytag}</h2>
                    <div dangerouslySetInnerHTML={{ __html: CMSService.getRichText(publication.longDescription)}}></div>
                </div>
            </div>
        </div>
    )
}