import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Page404, ContentPage, PublicationPage, Layout, ScrollToTop } from "@vaccinessites/components";
import { CMSService } from "@vaccinessites/services";
import { config } from "./config";
import './ctvd.scss';

let defaultContent = require('./content/contentful-content.json');
let CMS= CMSService.initialize(defaultContent);

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Layout CMS={CMS} site={'ctvd'} gtmid = {config.gtmId}
          navOptions={{
            portalLogin: config.portalLogin}}>
          <Switch>
            <Route exact path="/"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/about/:slug"><ContentPage CMS={CMS} config={config}/></Route>
            <Route exact path="/about"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/vaccine/:slug"><ContentPage CMS={CMS} config={config}/></Route>
            <Route exact path="/vaccine"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/resources/:slug"><ContentPage CMS={CMS} config={config}/></Route>
            <Route exact path="/resources"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/communities/:slug"><ContentPage CMS={CMS} config={config}/></Route>
            <Route exact path="/contact-us"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/publication/:slug"><PublicationPage CMS={CMS} config={config}/></Route>
            <Route><Page404/></Route>
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
