const prod = {
    stage: "prod",
    portalLogin: "https://bmgf.sharepoint.com/sites/ctvd",
    gtmId: "GTM-M8GM5KT",
    publicationTileFileImagURL: "https://images.ctfassets.net/6y3xo20je9pr/7xmG0xO9PxT6cFLKU2f25K/98d58b6cb89e8cab0a0d0f22e4484bdc/File_Icon.PNG?h=250",
    baseAPIUrl: "https://ghvacapp0006prd.azurewebsites.net/api/"
};

const uat = {
    stage: "uat",
    portalLogin: "https://bmgf.sharepoint.com/sites/ctvd",
    gtmId: "GTM-58PBHW4",
    publicationTileFileImagURL: "https://images.ctfassets.net/6y3xo20je9pr/7xmG0xO9PxT6cFLKU2f25K/98d58b6cb89e8cab0a0d0f22e4484bdc/File_Icon.PNG?h=250",
    baseAPIUrl: "https://ghvacapp0006stg.azurewebsites.net/api/"
};

const dev = {
    stage: "dev",
    portalLogin: 'https://bmgf.sharepoint.com/sites/ctvd',
    gtmId: "GTM-58PBHW4",
    publicationTileFileImagURL: "https://images.ctfassets.net/6y3xo20je9pr/7xmG0xO9PxT6cFLKU2f25K/98d58b6cb89e8cab0a0d0f22e4484bdc/File_Icon.PNG?h=250",
    baseAPIUrl: "https://ghvacapp0006dev.azurewebsites.net/api/",
};


let env = dev;
if (process.env.REACT_APP_STAGE === 'production') {
    env = prod;
} else if (process.env.REACT_APP_STAGE === 'uat') {
    env = uat;
}

env.website = 'ctvd';
export const config = env;